import React, { Component, Fragment } from 'react';
import axios from "axios";
const config = require('../config.json');

export default class ProductAdmin extends Component {

  state = {
    newproduct: { 
      "id": "", 
      "firstname": "",
      "lastname": ""
    }
  }

  handleAddProduct = async (id, event) => {
    event.preventDefault();
    // add call to AWS API Gateway add product endpoint here
    try {
        const params = {
            "id": id,
            "firstname": this.state.newproduct.firstname,
            "lastname": this.state.newproduct.lastname
        };

        await axios.post(`${config.api.invokeUrl}/visitors/{id}`, params,{ 
          "headers": {
              "x-api-key": `${config.api.xapikey}`
            }
          });
        this.setState({ products: [...this.state.products, this.state.newproduct]});        
        this.setState({ newproduct: { "id": "", "firstname": "", "lastname": ""}});
    }catch (err){
        console.log(`An error has occurred: ${err}`);
    }


    this.setState({ products: [...this.state.products, this.state.newproduct] });
    this.setState({ newproduct: { "id": "", "firstname": "", "lastname": ""}});
  }


  onAddProductNameChange = event => this.setState({ newproduct: { ...this.state.newproduct, "firstname": event.target.value } });
  onAddProductIdChange = event => this.setState({ newproduct: { ...this.state.newproduct, "id": event.target.value } });
  onAddProductLastnameChange = event => this.setState({ newproduct: { ...this.state.newproduct, "lastname": event.target.value } });

  componentDidMount = () => {
    //this.fetchProducts();
  }

  render() {
    return (
      <Fragment>
        <section className="section">
          <div className="container">
            <h1>Product Admin</h1>
            <p className="subtitle is-5">Add and remove products using the form below:</p>
            <br />
            <div className="columns">
              <div className="column is-one-third">
                <form onSubmit={event => this.handleAddProduct(this.state.newproduct.id, event)}>
                  <div className="field has-addons">
                    <div className="control">
                      <input 
                        className="input is-medium"
                        type="text" 
                        placeholder="Enter firstname"
                        value={this.state.newproduct.firstname}
                        onChange={this.onAddProductNameChange}
                      />
                    </div>
                    <div className="control">
                      <input 
                        className="input is-medium"
                        type="text" 
                        placeholder="Enter id"
                        value={this.state.newproduct.id}
                        onChange={this.onAddProductIdChange}
                      />
                    </div>
                    <div className="control">
                      <input 
                        className="input is-medium"
                        type="text" 
                        placeholder="Enter lastname"
                        value={this.state.newproduct.lastname}
                        onChange={this.onAddProductLastnameChange}
                      />
                    </div>
                    <div className="control">
                      <button type="submit" className="button is-primary is-medium">
                        Add product
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}
