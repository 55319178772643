import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OTPClient from 'otp-client';
import OtpInput from 'react-otp-input';
import Headers from './header';
import Footers from './footer';
import axios from 'axios';
import './testOTP.css';
import { Redirect } from 'react-router';

const config = require('../config.json');

class otpPref extends Component {   
    constructor(props, context) {
		super(props, context);

        const LoginId = this.props.location.state.id;
        const LoginMobile = this.props.location.state.mobile;
        const LoginEmail = this.props.location.state.email;
    
        this.state = {
            otp: '',
            redirect: false,
            Id: this.props.location.state.id,
            Mobile: this.props.location.state.mobile,
            Email: this.props.location.state.email,
            Mode: 'Email', //this.props.location.state.mode,
            numInputs: 6,
            separator: '',
            isDisabled: false,
            hasErrored: false,
            isInputNum: false,
            minLength: 0,
            maxLength: 40,
            newOTP: '',
            showOTPError: "none",
            newVisitor: { 
                "id": this.props.location.state.id, 
                "firstname": this.props.location.state.firstname,
                "lastname": this.props.location.state.lastname,
                "company": this.props.location.state.company,
                "email": this.props.location.state.email,
                "laptop": this.props.location.state.laptop,
                "laptopserial": this.props.location.state.laptopserial,
                "mobile": this.props.location.state.mobile,
                "vehicle": this.props.location.state.vehicle,
                "image": this.props.location.state.image
              }
          };  

        }
      
        handleOtpChange = otp => {
          this.setState({ otp });
        };
      
        handleChange = e => {
          let currVal = e.target.value;
      
          if (e.target.name === 'numInputs') {
            const { minLength, maxLength } = this.state;
      
            if (currVal < minLength || currVal > maxLength) {
              currVal = 4;
      
              console.error(
                `Please enter a value between ${minLength} and ${maxLength}`
              );
            }
          }
      
          this.setState({ [e.target.name]: currVal });
        };
      
        clearOtp = () => {
          this.setState({ otp: '' });
        };
      
        handleCheck = e => {
          const { name } = e.target;
          this.setState(prevState => ({ [name]: !prevState[name] }));
        };
      
        handleSubmit = e => {
          e.preventDefault();
          //alert(this.state.otp);
          //alert(this.state.newOTP);
          if (this.state.otp == this.state.newOTP) {
            this.handleUpdateVisitor();
            //this.setState({ redirect: true });
          } else {
            //alert("not EQ");
            this.setState({ hasErrored: true });
            this.setState({ showOTPError: "block"});
          }
        };
      
        componentDidMount = () => {
          // console.log(this.state.Mode);
          if ("Email" == this.state.Mode)    {
            this.sendEmailMessage();
          }    
          if ("Mobile" == this.state.Mode)    {
            this.sendMessage();
          }            
         }

         resendOTP  = e => {
          e.preventDefault();
          if ("Email" == this.state.Mode)    {
            this.sendEmailMessage();
          }    
          if ("Mobile" == this.state.Mode)    {
            this.sendMessage();
          } 
        };
      
      sendMessage = () => {
          const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
          const otp = new OTPClient(secret);
          const token = otp.getToken();
          
          this.setState({newOTP: token}, function () {
              // console.log(this.state.newOTP);
          });
          
      
          var data = JSON.stringify({"message": "Vodacom Digital Visitor " + token,
                "token": `${config.api.SMStoken}`,                  
                "destination": this.state.Mobile});
        
            var config = {
              method: 'post',
              url: `${config.api.SMSUrl}`,
              headers: { 
                'Content-Type': 'application/json'
              },
              data : data
            };
          
          axios(config)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      
      }

      sendEmailMessage = async () => {
        const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
        const otp = new OTPClient(secret);
        const token = otp.getToken();
        
        this.setState({newOTP: token}, function () {
        //    console.log(this.state.newOTP);
        });

        const emailBody = "<span>Dear Visitor</span><p><span> Complete your update with the below OTP number: </span></p>" + token + "<p><span></span>Regards<br /><span>Digital Visitors Team</span></p>";
        
        const params = {
          "toaddress": this.state.Email.toLowerCase(),
          "subject": "Pernod Ricard Digital Visitor OTP",
          "body": emailBody
        };
        //console.log(params);
    
        try {
          const res = await axios.post(`${config.api.invokeUrl}/email/`, params, {
            "headers": {
              "x-api-key": `${config.api.xapikey}`
            }
          });
    
          if (res.status == "200") {
            // this.setState({ redirect: true });
          } else {
            console.log(`An error has occurred trying to send email`);
          }
    
        } catch (err) {
          console.log(`An error has occurred trying to send email: ${err}`);
        }
    
    }

    handleUpdateVisitor = async () => {
        // add call to AWS API Gateway add visitor endpoint here
        try {
            const params = {
                "id": this.state.newVisitor.id,
                "firstname": this.state.newVisitor.firstname,
                "lastname": this.state.newVisitor.lastname,
                "company": this.state.newVisitor.company,
                "email": this.state.newVisitor.email,
                "laptop": this.state.newVisitor.laptop,
                "laptopserial": this.state.newVisitor.laptopserial,
                "mobile": this.state.newVisitor.mobile,
                "vehicle": this.state.newVisitor.vehicle,
                "image": this.state.newVisitor.image
            };

            // console.log(params);
    
            await axios.patch(`${config.api.invokeUrl}/visitor/{id}`, params,{"headers": {
              "x-api-key": `${config.api.xapikey}`}});        
                     
            this.setState({redirect: true}, function () {
                this.setState({redirect: true})
            });
            
        }catch (err){
            console.log(`An error has occurred: ${err}`);
        }
    }
      
    render() {
          const {
            otp,
            numInputs,
            separator,
            isDisabled,
            hasErrored,
            isInputNum,
            minLength,
            maxLength,
            newOTP,
          } = this.state;

        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: "/visitordash",
                state: { id: this.state.Id}
              }} />;
        }
        return ( 
            <div>
                <Headers></Headers>
                <div className="text-center" style={{ margin: '30px 0px 0px 0px' }}>
                <p>
                <span style={{ fontSize: '15px'  }}>Email with OTP has been send for the registered ID</span>                             
                </p>
                {/* <span style={{ fontSize: '10px'  }}>OTP has been sent to {this.state.Mobile.substring(0,8)}**** or email address {this.state.Email.substring(0,15)}*****{(this.state.Email.split('@')[1]).split('.')[1]}</span> */}
                <span style={{ fontSize: '15px'  }}>OTP has been sent to email address {this.state.Email.substring(0,15)}*****{(this.state.Email.split('@')[1]).split('.')[1]}</span>
                <p>
                <span style={{ display: this.state.showOTPError, color: 'red' }}>Incorrect OTP captured, please click resend and try again</span>  
                </p> 
            <br/>
            <p/><h3>Capture OTP</h3> 
            <form onSubmit={this.handleSubmit}>
            <div className="containerOTP"> 
                <div className="view">
                    <div className="card">
                        <OtpInput
                            inputStyle={{
                                width: "3rem",
                                height: "3rem",
                                margin: "5px",
                                fontSize: "1rem",
                                borderRadius: 4,
                                border: "2px solid rgba(0,0,0,0.3)",
                                }}
                            numInputs={numInputs}
                            isDisabled={isDisabled}
                            hasErrored={hasErrored}
                            errorStyle="error"
                            onChange={this.handleOtpChange}
                            separator={<span>{separator}</span>}
                            isInputNum={isInputNum}
                            shouldAutoFocus
                            value={otp}
                        />
                        <div >
                            <button 
                            style={{margin: '10px 0px 0px 0px'}} 
                            className="btn btn-secondary" 
                            disabled={otp.length < numInputs}>
                            Submit
                        </button>    
                        <br/>                    
                        <button style={{margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} className="btn" onClick={this.resendOTP} >Resend OTP</button>        
                        </div>
                    </div>                    
                </div>
                                                   
            </div> 
            </form>
                      
                    

           
                </div>
            
              <Footers></Footers>              

            
            <br/>
            
        </div>              
            );
    }
}
 
export default otpPref;