import React, { Component } from 'react';
import Headers from './headerQR';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router';
import Back from "../return.png";
import "./footer.css";

var QRCode = require('qrcode.react');

class qr extends Component {    
    constructor(props, context) {
		super(props, context);

        const idLogin = this.props.location.state.id;  
        const dashFirstname = this.props.location.state.firstname;
        const dashLastname = this.props.location.state.lastname;        
        const dashImage = this.props.location.state.image;      

        this.state = {            
            redirect: false,
            directTo: "/",  
            showCapture: false,
            screenshot: null,
            showWebcam: "block",          
            id: idLogin,            
            firstname: dashFirstname,            
            lastname: dashLastname,            
            image: dashImage ,
            unqkey: this.props.location.state.unqkey           
          };  
        }

        handleReturn = e => {
            e.preventDefault();
              this.setState({ redirect: true });
          };

          componentDidMount = () => {
            // console.log(this.state.id);
            // console.log(this.state.firstname);
            // console.log(this.state.lastname);
           }
    
   render() { 
    if (this.state.redirect) {
        return <Redirect push to={{
            pathname: "/visitordash",
            state: { id: this.state.id}
          }} />;
    }
        return ( 
            <div>
                <Headers name={this.state.firstname + ` ` + this.state.lastname} image={this.state.image}></Headers>  
                   
                <div style={{ margin: '400px 0px 0px 0px'  }} className="text-center">
                    <h3>Scan your QR code</h3>
                    <br/>
                    <p>
                       <QRCode value={this.state.unqkey} /> 
                    </p>
                    <br/>
                    <Button onClick={this.handleReturn} variant="secondary">Return</Button>{' '}
                </div>

                <div className="wrapper">                            
                        <a className="center" >
                            <img  src={Back} onClick={this.handleReturn} ></img>
                        </a>                           
                </div>                      
            </div>              
        );
    }
}
 
export default qr;