import React, { Component } from 'react';
import headerImage1 from "../bg-desktop_0.jpeg"
import logo from "../pernod-ricard-just-logo-small.png";
import "./headerQR.css";
import profilePic from "../profilePic.png";

class HeadersQR extends Component {
    state = {  }
    render() { 
        return (
            <div>
            <div className="QRheader">
                <span>
                    <div id="QRwrapper">
                            <div id="QRfirst">
                            {
                                this.props.image === '' ? (   
                                    <img className="header-img-circular" src={profilePic} />   
                                ) : (
                                    <img className="header-img-circular" src={this.props.image} />    
                                )
                            }
                            </div>
                            <div id="QRsecond" >                  
                                <div><span>Hi,</span></div>
                                <div><span><b>{ this.props.name }</b></span></div>
                            </div>
                            <div id="QRthird">
                                <img src={ logo } className="headerLogo" style={{ margin: '20px 20px 0px 0px'}}></img>
                            </div>
                        </div>
                    </span>                    
                <img src={ headerImage1 } className="headerQRImg" />
            </div>
        </div>        
        );
    }
}
 
export default HeadersQR;