import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Headers from './components/header';
import Footer from './components/footer';
import Logon from './components/logon';
import OTPpref from './components/otpPref';
import OTPcapt from './components/otpCapt';
import VisitorDash from './components/visitordash';
import Profile from './components/profile';
import QR from './components/qr';
import Register from './components/register';
import Products from './components/Products';
import Admin from './components/ProductAdmin';
import Reject from './components/reject';
import BannerTest from './components/bannertest';
import VisitorTest from './components/visitor';
import testOTP from './components/testOTP';
import faq from './components/faq';
import survey from './components/survey';
import otpUpdate from './components/otpUpdate';
import otpUpdatePref from './components/otpUpdatePref';
import testTiles from './components/testTiles';


function App() {
  return (
    <div className="main_div">
        <Router>
    <div className="page-container">
       <div className="content-wrap">                                            
        <Switch>
          <Route exact path='/' component={Logon} />          
          <Route exact path='/otpPref' component={OTPpref} />          
          <Route exact path='/otpCapt' component={OTPcapt} />          
          <Route exact path='/visitordash' component={VisitorDash} />          
          <Route exact path='/profile' component={Profile} />          
          <Route exact path='/qr' component={QR} />          
          <Route exact path='/register' component={Register} />          
          <Route exact path='/Products' component={Products} />          
          <Route exact path='/ProductAdmin' component={Admin} />          
          <Route exact path='/reject' component={Reject} />          
          <Route exact path='/bannertest' component={BannerTest} />          
          <Route exact path='/visitor' component={VisitorTest} />                   
          <Route exact path='/testOTP' component={testOTP} />                   
          <Route exact path='/faq' component={faq} />                   
          <Route exact path='/survey' component={survey} />                   
          <Route exact path='/otpUpdate' component={otpUpdate} />                   
          <Route exact path='/otpUpdatePref' component={otpUpdatePref} />                   
          <Route exact path='/testTiles' component={testTiles} />                   
      </Switch>
        </div>        
      </div> 
  </Router>   
  
    </div>

  )
}

export default App;

