import React, { Component } from 'react';
import Headers from './headerVisitor';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import profileICO from "../admin.png";
import companyICO from "../enterprise.png";
import mobileICO from "../calls-contacts.png";
import emailICO from "../mail-new.png";
import idICO from "../privacy-or-confidential.png";
import picICO from "../photos.png";
import laptopICO from "../desktop.png";
import serialICO from "../shopping-checkout.png";
import carICO from "../car.png";
import "./profile.css";
import "./footerback.css";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import Modal from 'react-bootstrap/Modal';
import Webcam from 'react-webcam';
import logo from "../pernod-ricard-just-logo-small.png";
import returnimage from "../arrow-left-white.png";
import Back from "../return.png";
import cameraimage from "../camera.png";
import profilePic from "../profilePic.png";
import { Redirect } from 'react-router';
import axios from "axios";
import Footers from './footerProfile';

const config = require('../config.json');

class visitor extends Component {
    constructor(props, context) {
		super(props, context);

        this.handleShowCapture = this.handleShowCapture.bind(this);

        const idLogin = this.props.location.state.id;
        const emailLogin = this.props.location.state.email;
        const dashFirstname = this.props.location.state.firstname;
        const dashLastname = this.props.location.state.lastname;
        const dashCompany = this.props.location.state.company;
        const dashMobile = this.props.location.state.mobile;
        const dashLaptop = this.props.location.state.laptop;
        const dashLaptopSerial = this.props.location.state.laptopserial;
        const dashVehicle = this.props.location.state.vehicle;
        const dashImage = this.props.location.state.image;

        this.state = {            
            redirect: false,
            directTo: "/",  
            showCapture: false,
            showmobilematch: false,
            showemailmatch: false,
            screenshot: dashImage,
            showWebcam: "block",  
            errMsg_Mobile: "none",
            errMsg_name: "none",
            errMsg_lastname: "none",            
            errMsg_company: "none",            
            errMsg_email: "none",           
            original_email: emailLogin,           
            original_mobile: dashMobile,           
            newVisitor: { 
                "id": idLogin, 
                "firstname": dashFirstname,
                "lastname": dashLastname,
                "company": dashCompany,
                "email": emailLogin,
                "laptop": dashLaptop,
                "laptopserial": dashLaptopSerial,
                "mobile": dashMobile,
                "vehicle": dashVehicle,
                "image": dashImage 
              }              
          };  
        }

    onAddVisitorNameChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "firstname": event.target.value } });
    onAddVisitorIdChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "id": event.target.value } });
    onAddVisitorLastnameChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "lastname": event.target.value } });
    onAddVisitorCompanyChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "company": event.target.value } });
    onAddVisitorEmailChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "email": event.target.value } });
    onAddVisitorLaptopChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "laptop": event.target.value } });
    onAddVisitorLaptopserialChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "laptopserial": event.target.value } });
    onAddVisitorMobileChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": event.target.value } });
    onAddVisitorVehicleChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "vehicle": event.target.value } });
    
    screenshot() {
        // access the webcam trough this.refs
        var screenshot = this.refs.webcam.getScreenshot();
        this.setState({screenshot: screenshot});
        this.setState({showWebcam: "none"});
    }

    handleShowCapture() {
		this.setState({ showCapture: true });
    }

    handleClose = e => {
        this.setState({ showCapture: false });  
        this.setState({ showmobilematch: false });    
        this.setState({ showemailmatch: false });   
        this.setState({ showWebcam: "block"})       
    }

    handleChange = e => {
        this.form.validateFields(e.target);
      }

    handleReturn = e => {
        e.preventDefault();
        this.setState({ directTo: "/visitordash"})
        this.setState({ redirect: true });
      };

    updateSubmit = e => {
        e.preventDefault();
        
        let formValid = true;
        // console.log(e);

        //this.form.validateFields();
        
        if(this.state.newVisitor.firstname === '') {     
            this.setState({errMsg_name: "block"});    
            formValid = false;                
        }else{            
            this.setState({errMsg_name: "none"});
        } 
        
        if(this.state.newVisitor.lastname === '') {     
            this.setState({errMsg_lastname: "block"});    
            formValid = false;                
        }else{            
            this.setState({errMsg_lastname: "none"});
        } 

        if(this.state.newVisitor.company === '') {     
            this.setState({errMsg_company: "block"});    
            formValid = false;                
        }else{            
            this.setState({errMsg_company: "none"});
        } 

        if(this.state.newVisitor.mobile.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {     
            this.setState({errMsg_Mobile: "none"});                    
        }else{
            //this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": "" } });
            formValid = false;
            this.setState({errMsg_Mobile: "block"});
        } 

        if(this.state.newVisitor.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {     
            this.setState({errMsg_email: "none"});                
        }else{ 
            this.setState({errMsg_email: "block"});    
            formValid = false;  
        } 
        
       if(formValid)  {      
           // Check if email or mobile was changed.
           if((this.state.original_email !== this.state.newVisitor.email) ||
                (this.state.original_mobile !== this.state.newVisitor.mobile)) {
                console.log("email or mobile changed"); 
                this.handleValidateVisitor();
           } else {
                console.log('form is valid: submit');
                // No matches found
                //this.setState({ directTo: "otpUpdatePref"})
                this.setState({ directTo: "otpUpdate"})
                this.setState({ redirect: true });
                console.log('update record'); 
           }
       }
    };

    handleValidateVisitor = async () => {     
        try {
            const params = {
                "email": this.state.newVisitor.email,            
                "mobile": this.state.newVisitor.mobile,               
                "id": this.state.newVisitor.id               
            };
         
            // console.log(params);
            const res = await axios.post(`${config.api.invokeUrl}/visitorvalidate/`, params,{"headers": {
                "x-api-key": `${config.api.xapikey}`}});    
            // console.log(res.data);
            this.setState({ visitorsvalidate: res.data}, function () {           
            }); 
    
            if (this.state.visitorsvalidate.length == 0) {
                // No matches found
                //this.setState({ directTo: "otpUpdatePref"})
                this.setState({ directTo: "otpUpdate"})
                this.setState({ redirect: true });
                console.log('update record V');
            } else { 
                // Match found
                if(res.data[0].email === undefined) {
                    //  console.log('email undefined');
                } else {
                    // console.log('email match');
                    this.setState({ showemailmatch: true});
                }
                if(res.data[0].mobile === undefined) {
                    // console.log('mobile undefined');
                } else {
                    //  console.log('mobile match');
                    this.setState({ showmobilematch: true});
                }
            }
       
        }catch (err){
            console.log(`An error has occurred: ${err}`);
        }        
      }
    
   render() { 
    const videoConstraints = {
        width: 300,
        height: 300,
        facingMode: "user"
      };

    if (this.state.redirect) {
        return <Redirect push to={{
            pathname:  this.state.directTo,
            state: { 
                id: this.state.newVisitor.id,                
                firstname: this.state.newVisitor.firstname,
                lastname: this.state.newVisitor.lastname,
                company: this.state.newVisitor.company,
                email: this.state.newVisitor.email,
                laptop: this.state.newVisitor.laptop,
                laptopserial: this.state.newVisitor.laptopserial,
                mobile: this.state.newVisitor.mobile,
                vehicle: this.state.newVisitor.vehicle,
                image: this.state.screenshot
            }
          }} />;
    }
        return ( 
            <div>
                <div id="headerdivcontainer">
                    <div ></div>
                    <div className="aligncenter">
                        <div>
                            {
                                this.state.newVisitor.image === '' ? (
                                    <img style={{ margin: '10px 0px 0px 0px'}} className="img-circular" src={profilePic} />    
                                ) : (
                                    <img style={{ margin: '10px 0px 0px 0px'}} className="img-circular" src={this.state.newVisitor.image} />     
                                )
                            }
                            
                            <img id="cameraImage" src={cameraimage} style={{cursor:"pointer"}} onClick={this.handleShowCapture}/>                         
                        
                            <span style={{ margin: '0px 0px 0px -50px', cursor:"default"}}><b>{this.state.newVisitor.firstname + ` ` + this.state.newVisitor.lastname}</b></span> 
                        </div>                                               
                    </div>
                    <div style={{textAlign: "right"}}>
                        <img src={ logo } className="headerLogo" style={{ margin: '40px 40px 0px 0px'}} ></img> 
                    </div>
                </div>

                
                
                <div className="text-center" style={{ margin: '40px 0px 0px 0px'  }}>
                <FormWithConstraints
                    ref={form => this.form = form}
                    onSubmit={this.updateSubmit}
                    noValidate>
                    <div className="container">            
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <img src={profileICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="name"
                                id="name"
                                type="text"
                                placeholder = "First Name"
                                className="form-control+matching-text-style"
                                required
                                style={{ width: '85%'}}
                                value={this.state.newVisitor.firstname}
                                onChange={this.onAddVisitorNameChange}
                            />
                            <div style={{ display: this.state.errMsg_name, color: 'red' }}><p>First Name is mandatory, please complete</p></div>                            
                        </div>
                        <div className="form-group col-md-6">
                            <img src={profileICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="surname"
                                id="surname"
                                type="text"
                                placeholder = "Last Name"
                                className="form-control+matching-text-style"
                                required
                                style={{ width: '85%'}}
                                value={this.state.newVisitor.lastname}
                                onChange={this.onAddVisitorLastnameChange}
                            />
                            <div style={{ display: this.state.errMsg_lastname, color: 'red' }}><p>Last Name is mandatory, please complete</p></div>                        
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <img src={companyICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="Company"
                                id="Company"
                                type="text"
                                placeholder = "Company Name"
                                className="form-control+matching-text-style"
                                value={this.state.newVisitor.company}
                                onChange={this.onAddVisitorCompanyChange}
                                style={{ width: '85%'}}
                                required
                            />
                            <div style={{ display: this.state.errMsg_company, color: 'red' }}><p>Company name is mandatory, please complete</p></div>                                                    
                        </div>
                        <div className="form-group col-md-6">
                            <img src={mobileICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="Mobile"
                                id="Mobile"
                                type="text"
                                placeholder = "Contact Number"
                                className="form-control+matching-text-style"
                                value={this.state.newVisitor.mobile}
                                onChange={this.onAddVisitorMobileChange}
                                style={{ width: '85%'}}
                                required                                
                            />
                            <div style={{ display: this.state.errMsg_Mobile, color: 'red' }}><p>Invalid capture. Please complete the madatory field</p></div> 
                        </div>
                    </div> 
                    <div className="form-row">
                        <div className="form-group col-md-6">
                        <img src={emailICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="Email"
                                id="Email"
                                type="email"
                                placeholder = "Email address"
                                className="form-control+matching-text-style"
                                value={this.state.newVisitor.email}
                                onChange={this.onAddVisitorEmailChange}
                                style={{ width: '85%'}}
                                required                                
                            />
                           <div style={{ display: this.state.errMsg_email, color: 'red' }}><p>Invalid capture. Please complete the madatory field</p></div>
                        </div>
                        <div className="form-group col-md-6">
                        <img src={idICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="Id"
                                id="Id"
                                type="text"
                                placeholder = "RSA ID/Passport number"
                                className="form-control+matching-text-style"
                                required
                                style={{ width: '85%'}}
                                value={this.state.newVisitor.id}                                
                                readOnly
                            />                          
                        </div>
                    </div>     
                    <div className="form-row">
                        <div className="form-group col-md-6">
                        <img src={laptopICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="laptop"
                                id="laptop"
                                type="text"
                                placeholder = "Laptop name"
                                className="form-control+matching-text-style"
                                value={this.state.newVisitor.laptop}
                                onChange={this.onAddVisitorLaptopChange}
                                style={{ width: '85%'}}
                            />
                        </div>
                        <div className="form-group col-md-6">
                        <img src={serialICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="serial"
                                id="serial"
                                type="text"
                                placeholder = "Laptop serial number"
                                className="form-control+matching-text-style"
                                value={this.state.newVisitor.laptopserial}
                                onChange={this.onAddVisitorLaptopserialChange}
                                style={{ width: '85%'}}
                            />
                        </div>
                    </div> 
                    <div className="form-row">                        
                        <div className="form-group col-md-6">
                        <img src={carICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }}  />
                            
                            <input
                                name="Vehicle"
                                id="Vehicle"
                                type="text"
                                placeholder = "Vehicle registration"
                                className="form-control+matching-text-style"
                                value={this.state.newVisitor.vehicle}
                                onChange={this.onAddVisitorVehicleChange}
                                style={{ width: '85%'}}
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        <button style={{margin: '0px 0px 100px 0px'}} className="btn btn-secondary" >Update</button>
                    </div> 
                                    
                </div>
                </FormWithConstraints>                  

                <Modal show={this.state.showCapture} 
                onHide={this.handleClose} 
                backdrop="static"
                keyboard={false}
                style={{textAlign: "center"}}>
				
				<Modal.Body style={{textAlign: "center"}}>
                     
                    <Webcam 
                        audio ={false} 
                        ref='webcam'
                        screenshotFormat="image/jpeg"             
                        height={300}
                        width={300}
                        videoConstraints={videoConstraints}                         
                        style={{display: this.state.showWebcam}}/>
                   
                        { this.state.screenshot ? <img src={this.state.screenshot} style={{width: "300px", height: "250px"}} /> : null }
                    
                    <br/>
                    <br/>
                    <button style={{margin: '0px 10px 0px 0px', width: "100px"}} className="btn btn-info" onClick={this.screenshot.bind(this)}>Capture</button>
                    <Button style={{margin: '0px 10px 0px 0px', width: "100px"}} variant="secondary" onClick={this.handleClose}>
							Save
                    </Button>
                        <Button style={{ width: "100px"}} variant="danger" onClick={this.handleClose}>
							Cancel
                    </Button>
                    </Modal.Body>
					
			</Modal>  

            <Modal show={this.state.showemailmatch} 
                onHide={this.handleClose} 
                className="text-center"
                backdrop="static"
                keyboard={false}>
				<Modal.Body>
                    <p style={{color: "red"}}><b>Invalid email captured</b></p>
                    <span style={{fontSize: "12px"}}>The captured email is already linked to an existing ID number. <br/>Please capture an alternative email.</span>
                    <p></p>
                    <Button style={{width: "150px"}} variant="danger" onClick={this.handleClose}>
							Ok
                    </Button>
                </Modal.Body>					
			</Modal>

            <Modal show={this.state.showmobilematch} 
                onHide={this.handleClose} 
                className="text-center"
                backdrop="static"
                keyboard={false}>
				<Modal.Body>
                    <p style={{color: "red"}}><b>Invalid mobile number captured</b></p>
                    <span style={{fontSize: "12px"}}>The captured mobile number is already linked to an existing ID number. <br/>Please capture an alternative mobile number </span>
                    <p></p>
                    <Button style={{width: "150px"}} variant="danger" onClick={this.handleClose}>
							Ok
                    </Button>
                </Modal.Body>					
			</Modal>


                <div className="wrapper">                            
                        <a className="center" >
                            <img  src={Back} onClick={this.handleReturn} ></img>
                        </a>                           
                </div> 
            </div>                
        </div>              
            );
    }
}
 
export default visitor;