import React, { Component } from 'react';
import Headers from './headerQR';
import imgProfile from "../tile_profile.png";
import imgFaq from "../tile_faq.png";
import imgQr from "../tile_qr.png";
import imgSurvey from "../tile_survey.png";
import axios from "axios";
import { Redirect } from 'react-router';
import Exit from "../exit.png";
import "./footer.css";
import "./testTiles.css";
import imgAdmin from "../admin-white.png";
import imgChart from "../chart-line white.png";
import imgQrcode from "../qrcode -white.png";
import imgHelp from "../help-circle white.png";

const config = require('../config.json');

class visitor extends Component {
    constructor(props, context) {
        super(props, context);

        const LoginId = this.props.location.state.id;

        this.state = {
            redirect: false,
            directTo: "/",
            Id: this.props.location.state.id,
            newVisitor: {
                "id": "",
                "firstname": "",
                "lastname": "",
                "company": "",
                "email": "",
                "laptop": "",
                "laptopserial": "",
                "mobile": "",
                "vehicle": "",
                "image": "",
                "unqkey": ""
            },
            visitors: [],
            btnsDisabled: true
        };
    }


    componentDidMount = () => {
        // alert(this.state.Id);
        this.fetchVisitor();
    }

    fetchVisitor = async () => {
        try {

            const params = {
                "id": this.state.Id
            };

            const res = await axios.post(`${config.api.invokeUrl}/visitor/`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            this.setState({ visitors: res.data }, function () {
            });

            if (this.state.visitors.length == 0) {
                alert("Visitor information could not be loaded.");
            } else {
                this.setState({ newVisitor: { ...this.state.newVisitor, "id": res.data[0].id } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "company": res.data[0].company } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "firstname": res.data[0].firstname } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "lastname": res.data[0].lastname } });
                // this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": res.data[0].company } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": res.data[0].mobile } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "email": res.data[0].email } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "vehicle": res.data[0].vehicle } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "laptop": res.data[0].laptop } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "laptopserial": res.data[0].laptopserial } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "image": res.data[0].image } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "unqkey": res.data[0].unqkey } });
                this.setState({ btnsDisabled: false });
            }

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    onSelectProfile() {
        this.setState({ directTo: "/visitor" });
        this.setState({ redirect: true });
    }

    onSelectProfile = e => {
        e.preventDefault();
        this.setState({ directTo: "/profile" });
        this.setState({ redirect: true });
    }

    onSelectQR = e => {
        e.preventDefault();
        this.setState({ directTo: "/qr" });
        this.setState({ redirect: true });
    }

    onSelectFAQ = e => {
        e.preventDefault();
        this.setState({ directTo: "/faq" });
        this.setState({ redirect: true });
    }

    onSelectSurvey = e => {
        e.preventDefault();
        this.setState({ directTo: "/survey" });
        this.setState({ redirect: true });
    }

    render() {
        if (this.state.redirect) {
            // console.log(this.state.newVisitor.id);
            // console.log(this.state.newVisitor.firstname);
            // console.log(this.state.newVisitor.lastname);
            // console.log(this.state.newVisitor.image);
            return <Redirect push to={{
                pathname: this.state.directTo,
                state: {
                    id: this.state.newVisitor.id,
                    firstname: this.state.newVisitor.firstname,
                    lastname: this.state.newVisitor.lastname,
                    company: this.state.newVisitor.company,
                    mobile: this.state.newVisitor.mobile,
                    email: this.state.newVisitor.email,
                    vehicle: this.state.newVisitor.vehicle,
                    laptop: this.state.newVisitor.laptop,
                    laptopserial: this.state.newVisitor.laptopserial,
                    image: this.state.newVisitor.image,
                    unqkey: this.state.newVisitor.unqkey
                }
            }} />;
        }
        return (
            <div>
                <Headers name={this.state.newVisitor.firstname + ` ` + this.state.newVisitor.lastname} image={this.state.newVisitor.image}></Headers>
                <br />
                <div className="text-center">
                    <h3 style={{ marginTop: '350px' }}>Digital Visitor</h3>
                    <br />
                    <div style={{ alignItems: 'center', marginBottom: '70px' }}>
                        <button disabled={this.state.btnsDisabled} className="button button3" onClick={this.onSelectProfile}><img src={ imgAdmin }></img><br /><span style={{ color: "white" }}>Profile</span></button>
                        <button disabled={this.state.btnsDisabled} className="button button3" onClick={this.onSelectFAQ}><img src={ imgHelp }></img><br /><span style={{ color: "white" }}>FAQs</span></button>
                        <button disabled={this.state.btnsDisabled} className="button button3" onClick={this.onSelectQR}><img src={ imgQrcode }></img><br /><span style={{ color: "white" }}>QR Code</span></button>
                    </div>
                </div>

                <div className="wrapperdash">
                    <a className="right" href="/">
                        <img src={Exit}  ></img>
                    </a>
                </div>
            </div>
        );
    }
}

export default visitor;