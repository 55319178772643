import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OTPClient from 'otp-client';
import OtpInput from 'react-otp-input';
import Headers from './header';
import Footers from './footer';
import axios from 'axios';
import './testOTP.css';
import { Redirect } from 'react-router';

const config = require('../config.json');

const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
const otp = new OTPClient(secret);
const token = otp.getToken();

class otpPref extends Component {
  constructor(props, context) {
    super(props, context);

    const LoginId = this.props.location.state.id;
    const LoginMobile = this.props.location.state.mobile;
    const LoginEmail = this.props.location.state.email;
    const LoginMode = this.props.location.state.mode;

    this.state = {
      otp: '',
      redirect: false,
      Id: this.props.location.state.id,
      Mobile: this.props.location.state.mobile,
      Email: this.props.location.state.email,
      Mode: 'Email', //this.props.location.state.mode,
      numInputs: 6,
      separator: '',
      isDisabled: false,
      hasErrored: false,
      isInputNum: false,
      minLength: 0,
      maxLength: 40,
      newOTP: '',
      showOTPError: "none",
    };
  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };

  handleChange = e => {
    let currVal = e.target.value;

    if (e.target.name === 'numInputs') {
      const { minLength, maxLength } = this.state;

      if (currVal < minLength || currVal > maxLength) {
        currVal = 4;

        console.error(
          `Please enter a value between ${minLength} and ${maxLength}`
        );
      }
    }

    this.setState({ [e.target.name]: currVal });
  };

  clearOtp = () => {
    this.setState({ otp: '' });
  };

  handleCheck = e => {
    const { name } = e.target;
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  handleSubmit = e => {
    e.preventDefault();
    //alert(this.state.otp);
    //alert(this.state.newOTP);
    if (this.state.otp == this.state.newOTP) {
      this.setState({ redirect: true });
    } else {
      //alert("not EQ");
      this.setState({ hasErrored: true });
      this.setState({ showOTPError: "block" });
    }
  };

  componentDidMount = () => {
    // console.log(this.state.Mode);         
        
    // if ("Email" == this.state.Mode) {
    //   if (this.state.Email.includes("@")) {
    //     this.sendEmailMessage();
    //   } else {
    //     this.setState({ Email: "@" });
    //   }
    // }

    this.sendEmailMessage();
    this.sendSMSMessage();

    // if ("Mobile" == this.state.Mode) {
    //   this.sendSMSMessage();
    // }
  }

  resendOTP = e => {
    e.preventDefault();
    // if ("Email" == this.state.Mode) {
    //   this.sendEmailMessage();
    // }
    // if ("Mobile" == this.state.Mode) {
    //   this.sendSMSMessage();
    // }

    this.sendEmailMessage();
    this.sendSMSMessage();

  };

  sendSMSMessage = async () => {

  
    this.setState({ newOTP: token }, function () {
      // console.log(this.state.newOTP);
    });

    // const smsBody = "Dear " + hostName + ", Please be advised that " + visitorParty + " has arrived at " + building + " Reception Area.";
    // const smsBody = "You have requested a Login OTP. Please use OTP number in the reception interface: " + token + ". Regards, Digital Visitors Team";

    const smsBody = "Pernod Ricard Digital Visitor login: " + token;

    const params = {
      "tomobile": this.state.Mobile,
      "body": smsBody
    };

    // console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/sms/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`,
          'Content-Type': 'application/json'
        }
      });

      if (res.status === "201") {
        // this.setState({ redirect: true });
      } else {
        console.log(`An error has occurred trying to send sms`);
      }

    } catch (err) {
      console.log(`An error has occurred trying to send sms: ${err}`);
    }
  }

  sendEmailMessage = async () => {
    // const secret = 'TPQDAHVBZ5NBO5LFEQKC7V7UPATSSMFY';
    // const otp = new OTPClient(secret);
    // const token = otp.getToken();

    this.setState({ newOTP: token }, function () {
      // console.log(this.state.newOTP);
    });

    const emailBody = "<span>Dear Visitor</span><p><span> Complete your login to Digital Visitor with the below OTP number: </span></p>" + token + "<p><span></span>Regards,<br /><span>Digital Visitors Team</span></p>";

    const params = {
      "toaddress": this.state.Email.toLowerCase(),
      "subject": "Pernod Ricard Digital Visitor OTP",
      "body": emailBody
    };
    //console.log(params);

    try {
      const res = await axios.post(`${config.api.invokeUrl}/email/`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        },
        withCredentials: false
      });

      if (res.status == "200") {
        // this.setState({ redirect: true });
      } else {
        console.log(`An error has occurred trying to send email`);
      }

    } catch (err) {
      console.log(`An error has occurred trying to send email: ${err}`);
    }
  }

  render() {
    const {
      otp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
      minLength,
      maxLength,
      newOTP,
    } = this.state;

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: "/visitordash",
        state: { id: this.state.Id }
      }} />;
    }
    return (
      <div>
        <Headers></Headers>
        <div className="text-center" style={{ margin: '30px 0px 0px 0px' }}>
          <p>
            <span style={{ fontSize: '15px' }}>Email with OTP has been send for the registered ID</span>
          </p>
          {
            ("Email" == this.state.Mode) ?
              <span style={{ fontSize: '15px' }}>OTP has been sent to email address {this.state.Email.substring(0, 15)}*****{(this.state.Email.split('@')[1]).split('.')[1]}</span>
              :
              <span style={{ fontSize: '15px' }}>OTP has been sent to {this.state.Mobile.substring(0, 8)}**** </span>
          }
          <p>
            <span style={{ display: this.state.showOTPError, color: 'red' }}>Incorrect OTP captured, please click resend and try again</span>
          </p>
          <br />
          <p /><h3>Capture OTP</h3>
          <form onSubmit={this.handleSubmit}>
            <div className="containerOTP">
              <div className="view">
                <div className="card">
                  <OtpInput
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "5px",
                      fontSize: "1rem",
                      borderRadius: 4,
                      border: "2px solid rgba(0,0,0,0.3)",
                    }}
                    numInputs={numInputs}
                    isDisabled={isDisabled}
                    hasErrored={hasErrored}
                    errorStyle="error"
                    onChange={this.handleOtpChange}
                    separator={<span>{separator}</span>}
                    isInputNum={isInputNum}
                    shouldAutoFocus
                    value={otp}
                  />
                  <div >
                    <button
                      style={{ margin: '10px 0px 0px 0px' }}
                      className="btn btn-secondary"
                      disabled={otp.length < numInputs}>
                      Submit
                    </button>
                    <br />
                    <button style={{ margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} className="btn" onClick={this.resendOTP} >Resend OTP</button>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>
        <Footers></Footers>
        <br />

      </div>
    );
  }
}

export default otpPref;